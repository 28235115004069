/**
 * Blog teaser Block
 *
 * @author David Faber
 * @author Ahmed Al-Yasiri
 * @copyright (c) Peritus Webdesign GmbH
 **/
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './blog-teaser.module.scss';
import Block, {BlockShape} from '../../components/Block';
import {ButtonLinkProps} from '../../components/Button';
import {BlogPostCardProps} from '../../components/BlogPostCard';
import BlogPostCard from '../../components/BlogPostCard';
import ButtonLink from '../../components/Button/ButtonLink';

const BlogTeaser = ({title,hasButton, button, categories, ...props}) => {

    let posts = categories ? categories.reduce((currentPosts, cat) => [
        ...currentPosts,
        ...cat.posts.nodes,
    ], []) : [];

    posts = posts
        .sort((first, second) => new Date(second.date) - new Date(first.date))
        .slice(0, 3);

    return (
        <Block className={styles.blogTeaser} {...props}>
            {title && <h2 className={'text-center'} dangerouslySetInnerHTML={{__html: title}}/>}
            <div className={styles.posts}>
                {posts.length && posts.map((post, index) => (
                    <BlogPostCard key={index} post={post}/>
                ))}
            </div>
            {hasButton && <ButtonLink link={button} className={styles.button}/>}
        </Block>
    );
};

BlogTeaser.propTypes = {
    ...BlockShape,
    hasButton: PropTypes.bool,
    button: PropTypes.shape(ButtonLinkProps),
    categories: PropTypes.arrayOf(PropTypes.shape({
        acf: PropTypes.shape({
            withDate: PropTypes.bool.isRequired,
        }),
        posts: PropTypes.shape({
            nodes: PropTypes.arrayOf(BlogPostCardProps).isRequired,
        }).isRequired,
    })),
};

BlogTeaser.defualtProps = {
    hasButton: false,
};

export default BlogTeaser;
